@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  color: rgba(255, 255, 255,80%);
  background: linear-gradient(to bottom, #000102, #0f3b82);
  font-family: "Roboto", sans-serif;
  

}

.logo{
  font-family: "Rancho", cursive;
  font-weight: 400;
  font-style: normal;}

button:hover{
  scale: 1.05;
  transition: all;
  transition:  0.9s ease-in-out;
}
a:hover{
  scale: 1.05;
  transition: all;
  transition:  0.9s ease-in-out;
}


.test{
  background-color:rgba(57, 57, 182, 0.175) ;
  background-blend-mode: soft-light;
}
  

